/* eslint-disable react/jsx-no-useless-fragment */
import React, { PropsWithChildren, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Button } from '@mantine/core';
import { UserGroup } from '../../types/user';
import { useUserAccess } from '../../hooks/useUserAccess';
import { useAppContext } from '../../contexts/appContext';
import NoDataCard from '../../andromeda/noDataCard';

export interface UserGuardProps {
    userGroupWhitelist?: UserGroup[] | UserGroup;
    userGroupBlacklist?: UserGroup[] | UserGroup;
    redirect?: // ? Redirect to this page if the user does not have access
    | string
        | Array<{
              path: string;
              userGroup: UserGroup | UserGroup[];
          }>;
    // ? Use the Original user group to override the fake user group to access the page
    ruleOverRideWithOriginalGroup?: boolean;
}

export const UserGuard: React.FC<PropsWithChildren<UserGuardProps>> = ({
    userGroupWhitelist,
    userGroupBlacklist,
    redirect,
    children,
    ruleOverRideWithOriginalGroup,
}) => {
    const router = useRouter();
    const { user } = useAppContext();

    const hasAccess = useUserAccess({
        userGroupWhitelist,
        userGroupBlacklist,
        ruleOverRideWithOriginalGroup,
    });

    useEffect(() => {
        if (!router.isReady || hasAccess) return;

        const redirectPathArr = Array.isArray(redirect) ? redirect : [{ path: redirect }];
        const redirectPath = redirectPathArr.find(item =>
            Array.isArray(item.userGroup)
                ? user?.groups?.some(group => item.userGroup.includes(group))
                : user?.groups?.includes(item.userGroup),
        )?.path;

        router.push(redirectPath ?? '/');
    }, [hasAccess, redirect, router, router.isReady, ruleOverRideWithOriginalGroup, user]);

    if (!hasAccess)
        return (
            <div className="flex flex-col gap-3 justify-center items-center w-full h-screen">
                <NoDataCard>This Page is off Limits</NoDataCard>
                <Button loading={false} type="button" onClick={() => router.back()}>
                    Go Back
                </Button>
            </div>
        );

    return <>{children}</>;
};
