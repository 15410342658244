import ResponsiveLayout from '@/components/responsive/ResponsiveLayout';
import { CPGComponent } from '@/components/cpg/cpg';

export default function CPG() {
    return (
        <ResponsiveLayout title="CPG" className="!p-0 !mb-0">
            <CPGComponent />
        </ResponsiveLayout>
    );
}
