import React from 'react';
import {
    ChartLine,
    Flask,
    Megaphone,
    Package,
    Path,
    Tag,
    TrendUp,
    UserList,
    Wrench,
} from 'phosphor-react';
import { Microscope } from '@phosphor-icons/react';
import { NavigationCard, NavigationCardProps } from './NavigationCard';
import { UserGuard } from '../utils/UserGuard';

interface SpaceportHomeV2Props {
    userType: 'promos' | 'spaceport' | 'arena' | 'proximo' | 'wilsbach' | 'bausch' | 'bennu';
}

const getCards = (
    userType: 'promos' | 'spaceport' | 'arena' | 'proximo' | 'wilsbach' | 'bausch' | 'bennu',
): NavigationCardProps[] => [
    {
        icon: <Path size={44} />,
        title: 'Console',
        description: 'Check the status of all live BEES country-module pairs.',
        href: '/console',
        disabled: userType !== 'spaceport' && userType !== 'arena',
    },
    {
        icon: <ChartLine size={44} />,
        href: '/insights',
        title: 'Insights',
        description: 'Dive into model health and usage performance over time.',
    },
    {
        icon: <Flask size={44} />,
        title: 'Experiments',
        description: 'Track & analyze experiment performance.',
        href: '/experiments',
    },
    {
        icon: <UserList size={44} />,
        title: 'Personalizations',
        description: 'Inspect daily store recommendations.',
        href: '/personalizations',
        disabled: userType !== 'spaceport' && userType !== 'arena',
    },
    {
        icon: <Wrench size={44} />,
        title: 'Configurations',
        description: 'View the contextual text/image configurations, as seen in BEES.',
        href: '/configurations',
        disabled: userType !== 'spaceport' && userType !== 'arena',
    },

    {
        icon: <Tag size={44} />,
        title: 'Pricing intelligence',
        description: 'View pricing intelligence for your products.',
        href: '/pricing',
    },
    {
        icon: <Package size={44} />,
        title: 'Athena: Store Intelligence',
        description: 'View your on-premise sales assistant.',
        href: '/stores',
    },
    {
        icon: <TrendUp size={44} />,
        title: 'Market Performance',
        description: 'Understand & explore how your products performed across the market.',
        href: '/market-performance',
    },
    {
        icon: <Microscope size={44} />,
        title: 'Product Analysis',
        description: 'View product performance in detail overtime and compare against others',
        href: '/product-analysis',
    },
    {
        icon: <Megaphone size={44} />,
        title: 'Promos performance',
        description: 'View the performance of personalized promotions in BEES.',
        href: '/promos',
    },
];

export default function SpaceportHomeV2({ userType }: SpaceportHomeV2Props) {
    return (
        <UserGuard
            userGroupBlacklist={['insomnia-users']}
            redirect={[{ userGroup: 'insomnia-users', path: 'insomnia-dashboard' }]}
        >
            <div className="flex flex-col py-10 lg:py-20 lg:justify-center">
                <h1 className="text-3xl mb-10 px-4">
                    Welcome to {userType === 'proximo' ? 'Arena OneBrain' : 'Spaceport'}!
                </h1>
                <div>
                    <div className="grid auto-rows-fr gap-4 grid-cols-1 p-4 md:grid-cols-2 lg:grid-cols-3 my-auto">
                        {getCards(userType).map(
                            item => !item.hidden && <NavigationCard key={item.title} {...item} />,
                        )}
                    </div>
                </div>
                {userType !== 'proximo' && (
                    <p className="text-sm text-gray-400 mt-8">
                        For a detailed walkthrough of Spaceport, please contact{' '}
                        <a
                            href={
                                userType === 'promos'
                                    ? 'mailto:abi-promos-support@arena-ai.com'
                                    : 'mailto:nestle-algoselling-support@arena-ai.com'
                            }
                            className="underline hover:text-white"
                        >
                            {userType === 'promos'
                                ? 'abi-promos-support@arena-ai.com'
                                : 'support@arena-ai.com'}
                        </a>
                    </p>
                )}
            </div>
        </UserGuard>
    );
}
