import React from 'react';
import Link from 'next/link';
import cx from 'classnames';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { BareCard } from 'andromeda/Card';
import StyledTooltip from '../utils/styledTooltip';
import useHrefAccess from '../navBar/useHrefAccess';

interface StyledSpaceportBareCardProps {
    disabled?: boolean;
    newRelease: boolean;
}

const StyledSpaceportBareCard = styled(BareCard)<StyledSpaceportBareCardProps>`
    border-bottom: 0.5px solid transparent;
    border-right: 0.5px solid transparent;

    ${props =>
        !props.disabled &&
        `
        :hover {
        border: 0.5px solid ${
            props.newRelease ? 'rgba(33, 78, 236, 1)' : 'rgba(var(--primary-color), 0.4)'
        }  !important;
        box-shadow: 0px 0px 16px 4px ${
            props.newRelease ? 'rgba(33, 78, 236, 0.4)' : 'rgba(var(--primary-color), 0.4)'
        } !important;
    }
    `}
`;

export interface NavigationCardProps {
    icon: JSX.Element;
    title: string;
    description: string;
    tooltip?: string;
    disabled?: boolean;
    href: string;
    newRelease?: boolean;
    hidden?: boolean;
}

export const NavigationCard: React.FC<NavigationCardProps> = ({
    icon,
    title,
    description,
    tooltip,
    disabled,
    href,
    newRelease = false,
    hidden,
    ...props
}) => {
    const router = useRouter();
    const isVisible = useHrefAccess({ href: href?.replace('/', '') });
    if (!isVisible) return null;

    return (
        <StyledTooltip
            label={tooltip ?? ''}
            disabled={!tooltip || hidden}
            withinPortal
            zIndex={99999999}
        >
            <div className="h-full min-h-[90px]">
                <StyledSpaceportBareCard
                    newRelease={newRelease}
                    {...props}
                    disabled={disabled}
                    className={cx('p-4 h-full space-y-4 rounded col-span-1 transition-colors', {
                        'brightness-[0.80]': disabled,
                        hidden,
                    })}
                >
                    <Link
                        href={disabled ? router.pathname : href}
                        className={cx({
                            'cursor-not-allowed': disabled,
                            'text-primary-20': disabled,
                        })}
                    >
                        <div className="flex items-center justify-between">
                            {icon}
                            {newRelease && (
                                <p className="text-risd-blue font-semibold text-xs">NEW RELEASE</p>
                            )}
                        </div>
                        <h4 className="text-lg font-medium">{title}</h4>
                        <p
                            className={cx('text-primary-50', {
                                'text-primary-10 italic': disabled,
                            })}
                        >
                            {description}
                        </p>
                    </Link>
                </StyledSpaceportBareCard>
            </div>
        </StyledTooltip>
    );
};

export { StyledSpaceportBareCard };
