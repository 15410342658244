import ResponsiveLayout from '@/components/responsive/ResponsiveLayout';
import SpaceportHomeV2 from '../components/SpaceportHomeV2/SpaceportHomeV2';
import { useUserAccess } from '../hooks/useUserAccess';
import HermesHome from './hermesHome';
import Search from './search';
import CPG from './cpg';

const helper = (
    promo: boolean,
    arena: boolean,
    proximo: boolean,
    wilsbach: boolean,
    bausch: boolean,
    bennu: boolean,
) => {
    if (arena) return 'arena';
    if (promo) return 'promos';
    if (proximo) return 'proximo';
    if (wilsbach) return 'wilsbach';
    if (bausch) return 'bausch';
    if (bennu) return 'bennu';
    return 'spaceport';
};

const Home = () => {
    const isPromoUser = useUserAccess({ userGroupWhitelist: ['abi-promos-spaceport'] });
    const isArenaStaff = useUserAccess({ userGroupWhitelist: ['arena-staff'] });
    const isBauschStaff = useUserAccess({ userGroupWhitelist: ['bausch-users'] });
    const isDemoUser = useUserAccess({ userGroupWhitelist: ['demo-users'] });
    const isProximoUser = useUserAccess({ userGroupWhitelist: ['proximo-users'] });
    const isWilsbachUser = useUserAccess({ userGroupWhitelist: ['spaceport-US-WILSBACH-access'] });
    const isBennu = useUserAccess({ userGroupWhitelist: ['bennu-users'] });
    // TODO: Remove this once deployed to production
    const isCarlsbergUser =
        useUserAccess({ userGroupWhitelist: ['carlsberg-users', 'arena-staff'] }) ||
        (typeof window !== 'undefined' && window?.location?.hostname?.includes('carlsberg'));
    if (isBauschStaff) {
        return <Search />;
    }

    if (isDemoUser) {
        return <HermesHome />;
    }

    if (isCarlsbergUser) {
        return <CPG />;
    }

    return (
        <ResponsiveLayout title="Spaceport Home" className="!mb-0 md:!px-16 lg:!px-36 xl:!px-56">
            <SpaceportHomeV2
                userType={helper(
                    isPromoUser,
                    isArenaStaff,
                    isProximoUser,
                    isWilsbachUser,
                    isBauschStaff,
                    isBennu,
                )}
            />
        </ResponsiveLayout>
    );
};

export default Home;
